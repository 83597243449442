import axios from 'axios';
import authHeader from "@/service/AuthHeader";
import getEnvironment from "@/service/Environment";

const API_URL = getEnvironment().API_LOGGED_IN_URL;

export default class StatService {
    getEvents() {
        return axios.get(API_URL + 'event/list', { headers: authHeader() });
    }

    getUserRecipients(criteria) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.post(API_URL + 'export/users/recipients', criteria, { headers:  header });
    }

    getApplicantRecipients(criteria) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.post(API_URL + 'export/applicants/recipients', criteria, { headers:  header });
    }

    exportApplicants(criteria) {
        return axios.post(
            API_URL + 'export/applicants',
            {
                ...criteria
            },
            {
                headers: authHeader(),
                responseType: 'blob'
            },
        );
    }

    exportUsers(criteria) {
        return axios.post(
            API_URL + 'export/users',
            {
                ...criteria
            },
            {
                headers: authHeader(),
                responseType: 'blob'
            },
        );
    }

    exportAgeGroups(criteria) {
        return axios.post(
            API_URL + 'export/user-child-groups',
            {
                ...criteria
            },
            {
                headers: authHeader(),
                responseType: 'blob'
            },
        );
    }

    exportEventStatistics(criteria) {
        return axios.post(
            API_URL + 'export/statistics',
            {
                ...criteria
            },
            {
                headers: authHeader(),
                responseType: 'blob'
            },
        );
    }

    exportNumberOfApplicants(criteria) {
        return axios.post(
            API_URL + 'export/number-of-applicants',
            {
                ...criteria
            },
            {
                headers: authHeader(),
                responseType: 'blob'
            },
        );
    }

    exportApplicantsByDay(criteria) {
        return axios.post(
            API_URL + 'export/applicants-by-day',
            {
                ...criteria
            },
            {
                headers: authHeader(),
                responseType: 'blob'
            },
        );
    }
}
