import axios from 'axios';
import authHeader from "@/service/AuthHeader";
import getEnvironment from "@/service/Environment";

const API_LOGGED_IN_URL = getEnvironment().API_LOGGED_IN_URL + 'application';

export default class ApplicationService {
    delete(data) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.post(API_LOGGED_IN_URL + '/remove', data,{ headers: header });
    }

    removeDay(data) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.post(API_LOGGED_IN_URL + '/remove-day', data, { headers:  header });
    }

    removeFromWaitingList(data) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.put(API_LOGGED_IN_URL + '/approve', data, { headers:  header });
    }
}
