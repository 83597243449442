import axios from 'axios';
import authHeader from "@/service/AuthHeader";
import getEnvironment from "@/service/Environment";

const API_LOGGED_IN_URL = getEnvironment().API_LOGGED_IN_URL + 'event';

export default class EventService {
    getAll() {
        return axios.get(API_LOGGED_IN_URL + '/list', { headers: authHeader() });
    }

    getAllArchiveList() {
        return axios.get(API_LOGGED_IN_URL + '/archive-list', { headers: authHeader() });
    }

    getTemplateList() {
        return axios.get(API_LOGGED_IN_URL + '/template-list', { headers: authHeader() });
    }

    get(id) {
        return axios.get(API_LOGGED_IN_URL + `/view/${id}`, { headers: authHeader() });
    }

    create(data) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.post(API_LOGGED_IN_URL + '/save', data, { headers:  header });
    }

    archive(id, data) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.post(API_LOGGED_IN_URL + '/archive/' + id, data, { headers:  header });
    }

    update(id, data) {
        return axios.put(API_LOGGED_IN_URL + `/update/${id}`, data, { headers: authHeader() });
    }

    delete(id) {
        return axios.delete(API_LOGGED_IN_URL + `/delete/${id}`, { headers: authHeader() });
    }

    deleteGroup(id) {
        return axios.delete(API_LOGGED_IN_URL + `/delete-group/${id}`, { headers: authHeader() });
    }

    deleteImage(id) {
        return axios.delete(API_LOGGED_IN_URL + `/delete-image/${id}`, { headers: authHeader() });
    }
}
