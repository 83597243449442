import axios from 'axios';
import authHeader from "@/service/AuthHeader";
import getEnvironment from "@/service/Environment";

const API_URL = getEnvironment().API_LOGGED_IN_URL + 'email-template';

export default class EmailTemplateService {
    update(id, data) {
        return axios.put(API_URL + `/update/${id}`, data, { headers: authHeader() });
    }
}
