<template>
    <Toast/>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="mb-4" v-if="!permissionDenied">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Új" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"
                                    v-if="!archiveList"/>
                            <Button label="Törlés" icon="pi pi-trash" class="p-button-danger"
                                    @click="confirmDeleteSelected"
                                    :disabled="!selectedEvents || !selectedEvents.length" v-if="!archiveList"/>
                        </div>
                    </template>

                    <template v-slot:end>
                        <Button label="Export" icon="pi pi-download" class="p-button-help mr-2"
                                @click="exportToExcel"/>
                        <Button label="Archiváltak" icon="pi pi-inbox" class="p-button-warning"
                                @click="getArchiveList()" v-if="!archiveList"/>
                        <Button label="Vissza az aktív eseményekre" class="p-button-primary"
                                @click="getActiveList()" v-else/>
                    </template>
                </Toolbar>

                <DataTable ref="dt" :value="events" v-model:selection="selectedEvents" dataKey="id" :paginator="true"
                           :rows="10" :filters="filters" :loading="loading"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           :rowsPerPageOptions="[5,10,25]"
                           currentPageReportTemplate="" responsiveLayout="scroll" filterDisplay="row"
                           v-model:filters="filters"
                           :globalFilterFields="['name', 'dateType']">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">
                                <span v-if="!archiveList">Események</span>
                                <span v-else>Archivált események</span> <br>
                                <strong><small>Összesen: {{ total }}</small></strong>
                            </h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global'].value" placeholder="Keresés..."/>
                            </span>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 3rem" v-if="!archiveList"></Column>
                    <Column field="id" header="#" :sortable="true" headerStyle="width:5%; min-width:5rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">#</span>
                            {{ slotProps.data.id }}
                        </template>
                    </Column>
                    <Column field="name" header="Név" :sortable="true" headerStyle="width:20%; min-width:10rem;"
                            :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">Név</span>
                            {{ slotProps.data.name }}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                       class="p-column-filter" :placeholder="`Keresés...`"/>
                        </template>
                    </Column>
                    <Column field="dateType" header="Típus" :sortable="true" headerStyle="width:20%; min-width:10rem;"
                            :showFilterMenu="false">
                        <template #body="slotProps">
                            <span class="p-column-title">Típus</span>
                            {{ slotProps.data.dateType }}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                       class="p-column-filter" :placeholder="`Keresés...`"/>
                        </template>
                    </Column>
                    <Column field="template" header="Sablon-e" :sortable="true"
                            headerStyle="width:10%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Sablon-e</span>
                            {{ slotProps.data.template ? 'Igen' : 'Nem' }}
                        </template>
                    </Column>
                    <Column field="createdAt" header="Létrehozás dátuma" :sortable="true"
                            headerStyle="width:20%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Létrehozás dátuma</span>
                            {{ slotProps.data.createdAt }}
                        </template>
                    </Column>
                    <Column headerStyle="min-width:10rem;">
                        <template #body="slotProps">
                            <ConfirmPopup></ConfirmPopup>
                            <span v-if="slotProps.data.isArchive">
                                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                        @click="edit(slotProps.data)" v-tooltip.top="'Szerkesztés'"
                                        v-if="!permissionDenied"/>
                                 <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2"
                                         @click="show(slotProps.data)" v-tooltip.top="'Megtekintés'"/>
                                <br>
                                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mt-2 mr-2"
                                        v-if="!permissionDenied"
                                        @click="confirmDelete(slotProps.data)" v-tooltip.top="'Törlés'"/>
                                <Button icon="pi pi-history" class="p-button-rounded p-button-success mt-2 mr-2"
                                        @click="setToActive($event, slotProps.data)" v-tooltip.top="'Aktiválás'"
                                        v-if="!permissionDenied"/>
                            </span>
                            <span v-else>
                                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                        @click="edit(slotProps.data)" v-tooltip.top="'Szerkesztés'"
                                        v-if="!permissionDenied"/>
                                <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2"
                                        @click="show(slotProps.data)" v-tooltip.top="'Megtekintés'"/>
                                <br>
                                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mt-2 mr-2"
                                        v-if="!permissionDenied"
                                        @click="confirmDelete(slotProps.data)" v-tooltip.top="'Törlés'"/>
                                <Button icon="pi pi-inbox" class="p-button-rounded p-button-warning mt-2"
                                        v-if="!permissionDenied"
                                        @click="setToArchive($event, slotProps.data)" v-tooltip.top="'Archiválás'"/>
                            </span>
                        </template>
                    </Column>
                    <template #empty>
                        Nincs egyetlen esemény sem.
                    </template>
                </DataTable>

                <Dialog v-model:visible="eventDialog" :breakpoints="{'960px': '75vw', '640px': '100vw'}"
                        :style="{width: '70vw'}"
                        header="Esemény adatai" @hide="hideDialog"
                        :modal="true" class="p-fluid">
                    <TabView :style="{minHeight: '500px'}">
                        <TabPanel header="Magyar">
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="lastName">Esemény neve</label>
                                    <InputText id="lastName" v-model.trim="event.name" required="true"
                                               :class="{'p-invalid': submitted && !event.name}"/>
                                    <small class="p-invalid" v-if="submitted && !event.name">Esemény név megadása
                                        kötelező.</small>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="firstName">Rövid leírás</label>
                                    <Textarea id="description" v-model="shortDescription" rows="3" cols="20"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="firstName">Leírás</label>
                                    <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                                    <small class="p-invalid" v-if="submitted && !description">Esemény leírás megadása
                                        kötelező.</small>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col-2">
                                    <label for="firstName">Kép</label>
                                    <FileUpload mode="basic" name="image" :customUpload="true" @uploader="onUpload"
                                                :auto="true"
                                                :maxFileSize="10000000" class="p-button-outlined p-button-plain"
                                                :chooseLabel="image"></FileUpload>
                                </div>
                                <div class="field col-4" style="margin-top: 30px" v-if="this.imageUrl">
                                    <Button label="Kép megtekintése" icon="pi pi-eye" class="p-button-sm"
                                            @click="openImage"/>
                                </div>
                                <div class="field col-4" style="margin-top: 30px" v-if="this.imageUrl">
                                    <Button v-if="imageUploaded" label="Kép törlése" icon="pi pi-times"
                                            class="p-button-danger p-button-sm"
                                            @click="deleteImage($event)"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="name">Sablon</label> <br>
                                    <InputSwitch v-model="event.template"/>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Fordítások">
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="firstName">Esemény neve angolul</label>
                                    <InputText id="firstName" v-model.trim="event.nameEnglish"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="firstName">Rövid leírás angolul</label>
                                    <Textarea id="shortDescriptionEnglish" v-model="shortDescriptionEnglish" rows="3"
                                              cols="20"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="firstName">Leírás angolul</label>
                                    <ckeditor :editor="editor" v-model="descriptionEnglish"
                                              :config="editorConfig"></ckeditor>
                                </div>
                            </div>

                        </TabPanel>
                        <TabPanel header="Beállítások">

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="name">Egyszerűsített jelentkezés</label> <br>
                                    <InputSwitch v-model="event.simplifiedApplication"/>
                                </div>

                                <div class="field col">
                                    <label for="name">Gyermek nélküli jelentkezés</label> <br>
                                    <InputSwitch v-model="event.withoutChildrenApplication"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <!--                                <div class="field col">-->
                                <!--                                    <label for="name">Egész hétre jelentkezés</label> <br>-->
                                <!--                                    <InputSwitch v-model="event.wholeWeekApplication"/>-->
                                <!--                                </div>-->
                                <div class="field col">
                                    <label for="name">Magyar nyelvtudás szükséges</label> <br>
                                    <InputSwitch v-model="event.needHungarianLanguage"/>
                                </div>
                                <div class="field col">
                                    <label for="name">Gyermek választó kikapcsolása</label> <br>
                                    <InputSwitch v-model="event.childrenSelectorOff"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="name">Jelentkezés tiltása a felhasználó tiltása esetén</label> <br>
                                    <InputSwitch v-model="event.disableApplicationWhenUserDisabled"/>
                                </div>

                            </div>
                            <div class="field col">
                              <label for="maximumAcclimatizationCount">Beszoktatós gyermekek maximális létszáma</label>
                              <InputText v-model.trim="event.maximumAcclimatizationCount"/>
                            </div>

                        </TabPanel>
                        <TabPanel header="Időpontok">
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="name">Láthatóság kezdete</label> <br>
                                    <Calendar inputId="calendar" v-model="event.visibilityStart" :firstDayOfWeek="2"
                                              :manualInput="false" dateFormat="yy. mm. dd."
                                              :class="{'p-invalid': submitted && !event.visibilityStart}"></Calendar>
                                    <small class="p-invalid" v-if="submitted && !event.visibilityStart">Láthatóság
                                        kezdete kötelező.</small>
                                </div>

                                <div class="field col">
                                    <label for="name">Láthatóság vége</label> <br>
                                    <Calendar inputId="calendar" v-model="event.visibilityEnd"
                                              dateFormat="yy. mm. dd."
                                              :class="{'p-invalid': submitted && !event.visibilityEnd}"></Calendar>
                                    <small class="p-invalid" v-if="submitted && !event.visibilityEnd">Láthatóság vége
                                        kötelező.</small>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="inventoryStatus">Időpont megadás típusa</label>
                                    <Dropdown id="inventoryStatus" v-model="event.dateTypeNumber" :options="dateTypes"
                                              optionLabel="label"
                                              :class="{'p-invalid': submitted && !event.dateTypeNumber}"
                                              placeholder="Időpont megadás típusa" @change="changeDateType($event)">
                                        <template #value="slotProps">
                                            <span v-if="slotProps.value">
                                                <span v-if="slotProps.value == '0' || slotProps.value.value == '0'">
                                                    Egyedi
                                                </span>
                                                <span v-else>Ciklikus</span>
                                            </span>
                                            <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                    </Dropdown>
                                    <small class="p-invalid" v-if="submitted && !event.dateTypeNumber">Válasszon egy
                                        időpont megadás típusát.</small>
                                </div>
                                <div class="field col-1">
                                    <Button icon="pi pi-plus" class="p-button-success" @click="addUniqueOrCycleDate"
                                            :style="{marginTop: '25px'}" :disabled="!event.dateTypeNumber"
                                            v-show="uniqueDates.length > 0"/>
                                </div>
                            </div>

                            <div class="formgrid grid" v-for="(uniqueDate, index) in uniqueDates" v-bind:key="index">
                                <div class="field col">
                                    <label for="name">Dátum</label> <br>
                                    <Calendar inputId="calendar" v-model="uniqueDate.date"
                                              dateFormat="yy. mm. dd."></Calendar>
                                </div>
                                <div class="field col">
                                    <label for="name">Nyitás</label> <br>
                                    <Calendar inputId="calendar" v-model="uniqueDate.open" :showTime="true"
                                              :timeOnly="true" :id="'uniqueDate-open-' + index"></Calendar>
                                </div>
                                <div class="field col">
                                    <label for="name">Zárás</label> <br>
                                    <Calendar inputId="calendar" v-model="uniqueDate.close" :showTime="true"
                                              :timeOnly="true" :id="'uniqueDate-close-' + index"></Calendar>
                                </div>
                                <div class="field col">
                                    <Button icon="pi pi-trash" class="p-button-danger" v-show="index > 0"
                                            @click="deleteUniqueDate(index)" :style="{marginTop: '25px'}"/>
                                </div>
                            </div>

                            <div class="formgrid grid" v-if="event.dateTypeNumber && event.dateTypeNumber === '1'">
                                <div class="field col-5">
                                    <label for="name">Ciklus kezdete</label> <br>
                                    <Calendar inputId="calendar" v-model="cycleDateStart"
                                              :class="{'p-invalid': submitted && Object.keys(cycleDates).length > 0 && !cycleDateStart}"
                                              dateFormat="yy. mm. dd."></Calendar>
                                </div>
                                <div class="field col-5">
                                    <label for="name">Ciklus vége</label> <br>
                                    <Calendar inputId="calendar" v-model="cycleDateEnd"
                                              :class="{'p-invalid': submitted && Object.keys(cycleDates).length > 0 && !cycleDateEnd}"
                                              dateFormat="yy. mm. dd."></Calendar>
                                </div>
                            </div>

                            <div v-for="(cycleDate, index) in cycleDates" v-bind:key="index">
                                <div class="formgrid grid" v-for="(openingHour, j) in cycleDate.openingHours"
                                     v-bind:key="j">
                                    <div class="field col-5">
                                        <label for="name">{{ cycleDate.dayText }} nyitás</label> <br>
                                        <Calendar inputId="calendar" v-model="openingHour.open" :showTime="true"
                                                  :timeOnly="true"
                                                  :id="'cycleDate-open-' + cycleDate.day + '-' + j"></Calendar>
                                    </div>
                                    <div class="field col-5">
                                        <label for="name">{{ cycleDate.dayText }} zárás</label> <br>
                                        <Calendar inputId="calendar" v-model="openingHour.close" :showTime="true"
                                                  :timeOnly="true"
                                                  :id="'cycleDate-close-' + cycleDate.day + '-' + j"></Calendar>
                                    </div>
                                    <div class="field col-1">
                                        <Button icon="pi pi-plus" class="p-button-success" v-show="j === 0"
                                                @click="addCycleDateOpeningHour(index)"
                                                :style="{marginTop: '25px'}"/>
                                        <Button icon="pi pi-trash" class="p-button-danger" v-show="j > 0"
                                                @click="deleteCycleDateOpeningHour(index, j)"
                                                :style="{marginTop: '25px'}"/>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div>
                                <div class="formgrid grid" v-if="Object.keys(this.cycleDates).length > 0">
                                    <div class="field col">
                                        <h4>Időpont kivételek</h4>
                                    </div>
                                    <div class="field col">
                                        <Button icon="pi pi-plus" class="p-button-success p-button-sm"
                                                @click="addExcludeDate()"/>
                                    </div>
                                    <hr>
                                </div>

                                <div class="formgrid grid" v-for="(excludeDate, index) in excludeDates"
                                     v-bind:key="index">
                                    <div class="field col">
                                        <label for="name">Dátum</label> <br>
                                        <Calendar inputId="calendar" v-model="excludeDate.date"
                                                  dateFormat="yy. mm. dd." :manualInput="false"></Calendar>
                                    </div>
                                    <div class="field col">
                                        <label for="name">Nyitás</label> <br>
                                        <Calendar inputId="calendar" v-model="excludeDate.open" :showTime="true"
                                                  :timeOnly="true" :id="'excludeDate-open-' + index"></Calendar>
                                    </div>
                                    <div class="field col">
                                        <label for="name">Zárás</label> <br>
                                        <Calendar inputId="calendar" v-model="excludeDate.close" :showTime="true"
                                                  :timeOnly="true" :id="'excludeDate-close-' + index"></Calendar>
                                    </div>
                                    <div class="field col">
                                        <Button icon="pi pi-trash" class="p-button-danger"
                                                @click="deleteExcludeDate(index)" :style="{marginTop: '25px'}"/>
                                    </div>
                                </div>

                                <div class="formgrid grid mt-6" v-if="Object.keys(this.cycleDates).length > 0">
                                    <div class="field col">
                                        <h4>Extra időpontok</h4>
                                    </div>
                                    <div class="field col">
                                        <Button icon="pi pi-plus" class="p-button-success p-button-sm"
                                                @click="addExtraDate()"/>
                                    </div>
                                    <hr>
                                </div>

                                <div class="formgrid grid" v-for="(extraDate, index) in extraDates" v-bind:key="index">
                                    <div class="field col">
                                        <label for="name">Dátum</label> <br>
                                        <Calendar inputId="calendar" v-model="extraDate.date"
                                                  dateFormat="yy. mm. dd." :manualInput="false"></Calendar>
                                    </div>
                                    <div class="field col">
                                        <label for="name">Nyitás</label> <br>
                                        <Calendar inputId="calendar" v-model="extraDate.open" :showTime="true"
                                                  :timeOnly="true" :id="'extraDate-open-' + index"></Calendar>
                                    </div>
                                    <div class="field col">
                                        <label for="name">Zárás</label> <br>
                                        <Calendar inputId="calendar" v-model="extraDate.close" :showTime="true"
                                                  :timeOnly="true" :id="'extraDate-close-' + index"></Calendar>
                                    </div>
                                    <div class="field col">
                                        <Button icon="pi pi-trash" class="p-button-danger"
                                                @click="deleteExtraDate(index)" :style="{marginTop: '25px'}"/>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Esemény űrlap beállításai">
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="inventoryStatus">Intervallum felosztása</label>
                                    <Dropdown id="inventoryStatus" v-model="event.interval" :options="intervals"
                                              optionLabel="label" :class="{'p-invalid': submitted && !event.interval}"
                                              placeholder="Intervallum felosztása">
                                        <template #value="slotProps">
                                            <span v-if="slotProps.value">
                                                {{ slotProps.value.label }}
                                            </span>
                                            <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                    </Dropdown>
                                    <small class="p-invalid" v-if="submitted && !event.interval">Intervallum felosztása
                                        kötelező.</small>
                                </div>

                                <div class="field col">
                                    <label for="inventoryStatus">Látható időpontok</label>
                                    <Dropdown id="inventoryStatus" v-model="event.visibleDate" :options="visibleDate"
                                              optionLabel="label"
                                              :class="{'p-invalid': submitted && !event.visibleDate}"
                                              placeholder="Látható időpontok">
                                        <template #value="slotProps">
                                            <span v-if="slotProps.value">
                                                {{ slotProps.value.label }}
                                            </span>
                                            <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                    </Dropdown>
                                    <small class="p-invalid" v-if="submitted && !event.visibleDate">Látható időpontok
                                        kötelező.</small>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="inventoryStatus">Aktuális hét zárásának napja</label>
                                    <Dropdown v-model="event.weekCloseDay" :options="weekCloseDays"
                                              optionLabel="label"
                                              :class="{'p-invalid': submitted && !event.weekCloseDay}"
                                              placeholder="Aktuális hét zárásának napja">
                                        <template #value="slotProps">
                                            <span v-if="slotProps.value">
                                                {{ slotProps.value.label }}
                                            </span>
                                            <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                    </Dropdown>
                                    <small class="p-invalid" v-if="submitted && !event.weekCloseDay">Aktuális hét
                                        zárásának napja kötelező.</small>
                                </div>

                                <div class="field col">
                                    <label for="inventoryStatus">Aktuális hét zárásának ideje</label>
                                    <Calendar inputId="calendar" v-model="event.weekCloseTime" :showTime="true"
                                              :timeOnly="true"
                                              :class="{'p-invalid': submitted && !event.weekCloseTime}"></Calendar>
                                    <small class="p-invalid" v-if="submitted && !event.weekCloseTime">Aktuális hét
                                        zárásának ideje kötelező.</small>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="inventoryStatus">Összes jelentkező maximális létszáma</label>
                                    <InputText id="lastName" v-model.trim="event.maximumApplicationsCount"
                                               required="true"
                                               :class="{'p-invalid': submitted && !event.maximumApplicationsCount}"/>
                                    <small class="p-invalid" v-if="submitted && !event.maximumApplicationsCount">
                                        Összes jelentkező maximális létszámának megadása kötelező.
                                    </small>
                                </div>

                                <div class="field col">
                                    <label for="inventoryStatus">Összes várólistás maximális létszáma</label>
                                    <InputText v-model.trim="event.maximumWaitingListApplicationsCount"/>
                                </div>

                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="inventoryStatus">Aktuális hét mutatása</label>
                                    <Dropdown id="inventoryStatus" v-model="event.showActualWeek"
                                              :options="showActualWeek"
                                              :class="{'p-invalid': submitted && !event.showActualWeek}"
                                              optionLabel="label"
                                              placeholder="Aktuális hét mutatása">
                                        <template #value="slotProps">
                                            <span v-if="slotProps.value">
                                                {{ slotProps.value.label }}
                                            </span>
                                            <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                    </Dropdown>
                                    <small class="p-invalid"
                                           v-if="submitted && !event.showActualWeek">
                                        Válasszon egyet az aktuális hét mutatásából.
                                    </small>
                                </div>

                                <div class="field col">
                                    <label for="inventoryStatus">Gyermek korának vizsgálata</label>
                                    <Dropdown id="inventoryStatus" v-model="event.whenCheckChildAge"
                                              :options="whenCheckChildAge"
                                              :class="{'p-invalid': submitted && !event.whenCheckChildAge}"
                                              optionLabel="label"
                                              placeholder="Gyermek korának vizsgálata">
                                        <template #value="slotProps">
                                            <span v-if="slotProps.value">
                                                {{ slotProps.value.label }}
                                            </span>
                                            <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                    </Dropdown>
                                    <small class="p-invalid"
                                           v-if="submitted && !event.whenCheckChildAge">
                                        Válasszon egyet a gyermek korának vizsgálatából.
                                    </small>
                                </div>
                            </div>


                            <div class="formgrid grid mt-3">
                                <div class="field col">
                                    <h5>Gyermek nélküli jelentkezés esetén kitöltendő adatok</h5>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="name">Naponta hány idősáv engedélyezett?</label> <br>
                                    <InputText v-model.trim="event.dailyMaximumTimeSlot"/>
                                </div>
                                <div class="field col">
                                    <label for="name">Heti hány idősáv engedélyezett?</label> <br>
                                    <InputText v-model.trim="event.weeklyMaximumTimeSlot"/>
                                </div>
                                <div class="field col">
                                    <label for="name">Heti hány nap engedélyezett?</label> <br>
                                    <InputText v-model.trim="event.weeklyMaximumDays"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="name">Várólista típusa</label> <br>
                                    <Dropdown
                                        v-model="event.waitingListMethodOfEntry"
                                        :options="waitingListMethodOfEntry"
                                        optionLabel="label"
                                        placeholder="Várólista típusa"
                                    >
                                        <template #value="slotProps">
                                            <span v-if="slotProps.value">
                                                {{ slotProps.value.label }}
                                            </span>
                                            <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                    </Dropdown>
                                </div>
                            </div>

                        </TabPanel>
                        <TabPanel header="Csoportok">
                            <div class="formgrid grid mt-1">
                                <div class="field col">
                                    <h5>Gyermekkel történő jelentkezés esetén kitöltendő adatok</h5>
                                </div>
                            </div>

                            <div v-for="(group, index) in groups" v-bind:key="index">
                                <div class="formgrid grid">
                                    <div class="field col">
                                        <label for="name">Gyermek minimális életkora</label> <br>
                                        <InputText id="lastName" v-model.trim="group.minimumAgeLimit"
                                                   :class="{'p-invalid': submitted && group.minimumAgeLimit === ''}"/>
                                        <small class="p-invalid"
                                               v-if="submitted && group.minimumAgeLimit === ''">
                                            Gyermek minimális életkora kötelező.
                                        </small>
                                    </div>
                                    <div class="field col">
                                        <label for="name">&nbsp;</label> <br>
                                        <Dropdown id="inventoryStatus" v-model="group.minimumAgeLimitMetric"
                                                  :options="ageLimitMetric"
                                                  :class="{'p-invalid': submitted && !group.minimumAgeLimitMetric}"
                                                  optionLabel="label"
                                                  placeholder="Mértékegység">
                                            <template #value="slotProps">
                                            <span v-if="slotProps.value">
                                                {{ slotProps.value.label }}
                                            </span>
                                                <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                            </template>
                                        </Dropdown>
                                        <small class="p-invalid"
                                               v-if="submitted && !group.minimumAgeLimitMetric">
                                            Gyermek minimális életkorának mértékegysége kötelező.
                                        </small>
                                    </div>
                                </div>

                                <div class="formgrid grid">
                                    <div class="field col">
                                        <label for="name">Gyermek maximális életkora</label> <br>
                                        <InputText id="lastName" v-model.trim="group.maximumAgeLimit"
                                                   :class="{'p-invalid': submitted && group.maximumAgeLimit === ''}"/>
                                        <small class="p-invalid"
                                               v-if="submitted && group.maximumAgeLimit === ''">
                                            Gyermek maximális életkora kötelező.
                                        </small>
                                    </div>
                                    <div class="field col">
                                        <label for="name">&nbsp;</label> <br>
                                        <Dropdown id="inventoryStatus" v-model="group.maximumAgeLimitMetric"
                                                  :options="ageLimitMetric"
                                                  :class="{'p-invalid': submitted && !group.maximumAgeLimitMetric}"
                                                  optionLabel="label"
                                                  placeholder="Mértékegység">
                                            <template #value="slotProps">
                                            <span v-if="slotProps.value">
                                                {{ slotProps.value.label }}
                                            </span>
                                                <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                            </template>
                                        </Dropdown>
                                        <small class="p-invalid"
                                               v-if="submitted && !group.maximumAgeLimitMetric">
                                            Gyermek maximális életkorának mértékegysége kötelező.
                                        </small>
                                    </div>
                                </div>

                                <div class="formgrid grid">
                                    <div class="field col">
                                        <label for="name">Jelentkezés maximális létszáma</label> <br>
                                        <InputText id="lastName" v-model.trim="group.maximumApplicationsCount"
                                                   :class="{'p-invalid': submitted && !group.maximumApplicationsCount}"/>
                                        <small class="p-invalid"
                                               v-if="submitted && !group.maximumApplicationsCount">
                                            Jelentkezés maximális létszáma kötelező.
                                        </small>
                                    </div>
                                    <div class="field col">
                                        <label for="name">Várólista maximális létszáma</label> <br>
                                        <InputText v-model.trim="group.maximumWaitingListApplicationsCount"/>
                                    </div>
                                </div>

                                <div class="formgrid grid">
                                    <div class="field col">
                                        <label for="name">Naponta hány idősáv engedélyezett?</label> <br>
                                        <InputText id="lastName" v-model.trim="group.dailyMaximumTimeSlot"
                                                   :class="{'p-invalid': submitted && group.dailyMaximumTimeSlot === ''}"/>
                                        <small class="p-invalid"
                                               v-if="submitted && group.dailyMaximumTimeSlot === ''">
                                            Naponta hány óra engedélyezett mező megadása kötelező.
                                        </small>
                                    </div>
                                    <div class="field col">
                                        <label for="name">Heti hány idősáv engedélyezett?</label> <br>
                                        <InputText id="lastName" v-model.trim="group.weeklyMaximumTimeSlot"
                                                   :class="{'p-invalid': submitted && group.weeklyMaximumTimeSlot === ''}"/>
                                        <small class="p-invalid"
                                               v-if="submitted && group.weeklyMaximumTimeSlot === ''">
                                            Heti hány óra engedélyezett mező megadása kötelező.
                                        </small>
                                    </div>
                                    <div class="field col">
                                        <label for="name">Heti hány nap engedélyezett?</label> <br>
                                        <InputText id="lastName" v-model.trim="group.weeklyMaximumDays"/>
                                    </div>
                                </div>

                                <div class="formgrid grid">
                                    <div class="field col">
                                        <label for="name">Várólista típusa</label> <br>
                                        <Dropdown v-model="group.waitingListMethodOfEntry"
                                                  :options="waitingListMethodOfEntry"
                                                  :class="{'p-invalid': submitted && !group.waitingListMethodOfEntry}"
                                                  optionLabel="label"
                                                  placeholder="Várólista típusa">
                                            <template #value="slotProps">
                                            <span v-if="slotProps.value">
                                                {{ slotProps.value.label }}
                                            </span>
                                                <span v-else>
                                                {{ slotProps.placeholder }}
                                            </span>
                                            </template>
                                        </Dropdown>
                                        <small class="p-invalid"
                                               v-if="submitted && !group.waitingListMethodOfEntry">
                                            Kérem válasszon a várólista típusok közül.
                                        </small>
                                    </div>
                                </div>

                                <div class="formgrid grid">
                                    <div class="field col">
                                        <Button label="Törlés" icon="pi pi-trash" class="p-button-danger"
                                                @click="deleteGroup(index, group.id)" :style="{marginTop: '25px'}"/>
                                    </div>
                                </div>
                                <hr>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <Button label="Új csoport" icon="pi pi-plus" class="p-button-success"
                                            @click="addGroup" :style="{marginTop: '25px'}"/>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                    <template #footer>
                        <Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="Mentés" icon="pi pi-check" class="p-button-success" @click="saveEvent"
                                :loading="loading"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="showEventDialog" header="Esemény adatai"
                        :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '70vw'}"
                        :modal="true" :closable="false" class="p-fluid">
                    <TabView :style="{minHeight: '700px'}">
                        <TabPanel header="Jelentkezések naptárban">
                            <FullCalendar :options="calendarOptions" ref="calendar" v-if="calendarLoaded"
                                          :style="{height: '500px'}">
                                <template #eventContent="arg">
                                    <span v-tooltip="{value: arg.event.extendedProps.tooltip, escape: true }"
                                          style="cursor: pointer">
                                        {{ `${arg.timeText} - ${arg.event.title}` }}
                                    </span>
                                </template>
                            </FullCalendar>
                            <div class="formgrid grid">
                                <div class="field col text-center">
                                    <i class="pi pi-spin pi-spinner" style="font-size: 3rem; margin-top: 300px"
                                       v-if="!calendarLoaded"></i>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Jelentkezések lista">
                            <DataTable :value="event.applies" class="mt-1 applications-table" responsiveLayout="scroll"
                                       v-model:selection="selectedUsers"
                                       dataKey="id" :paginator="true" :rows="25" :rowsPerPageOptions="[25, 50, 100]"
                                       :loading="applicationLoading">
                                <template #header>
                                    <div
                                        class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                        <h5 class="m-0">Jelentkezések <br> <strong><small>Összesen:
                                            {{ event.applies.length }}</small></strong></h5>
                                        <div class="width: 100px">
                                            <Button label="Üzenet küldése" icon="pi pi-envelope"
                                                    class="p-button-primary"
                                                    @click="confirmMessageSelected"
                                                    :disabled="!selectedUsers || !selectedUsers.length"/>
                                        </div>
                                    </div>
                                </template>
                                <Column selectionMode="multiple" headerStyle="width: 1rem"></Column>
                                <Column field="applier.name" header="Jelentkező neve"></Column>
                                <Column field="applier.email" header="E-mail cím"></Column>
                                <Column field="isSimplifiedApplication" header="Egyszerűsített"></Column>
                                <Column field="start" header="Időpont"></Column>
                                <Column headerStyle="min-width:10rem;">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                                @click="confirmDeleteApplication(slotProps.data)"/>
                                    </template>
                                </Column>
                                <template #empty>
                                    <div class="my-3">
                                        Nincs egyetlen jelentkezés sem.
                                    </div>
                                </template>
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="Várólista">
                            <DataTable :value="event.waitingListApplies" class="mt-1 applications-table"
                                       responsiveLayout="scroll"
                                       v-model:selection="selectedWaitingUsers"
                                       dataKey="id" :paginator="true" :rows="25" :rowsPerPageOptions="[25, 50, 100]"
                                       :loading="waitingListLoading">
                                <template #header>
                                    <div
                                        class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                        <h5 class="m-0">Várólista <br> <strong><small>Összesen:
                                            {{ event.waitingListApplies.length }}</small></strong></h5>
                                        <div class="width: 100px">
                                            <Button label="Üzenet küldése" icon="pi pi-envelope"
                                                    class="p-button-primary"
                                                    @click="confirmMessageSelected"
                                                    :disabled="!selectedWaitingUsers || !selectedWaitingUsers.length"/>
                                        </div>
                                    </div>
                                </template>
                                <Column selectionMode="multiple" headerStyle="width: 1rem"></Column>
                                <Column field="applier.name" header="Jelentkező neve"></Column>
                                <Column field="applier.email" header="E-mail cím"></Column>
                                <Column field="isSimplifiedApplication" header="Egyszerűsített"></Column>
                                <Column field="start" header="Időpont"></Column>
                                <Column headerStyle="min-width:10rem;">
                                    <template #body="slotProps">
                                        <ConfirmPopup></ConfirmPopup>
                                        <Button icon="pi pi-history" class="p-button-rounded p-button-success mr-2"
                                                v-tooltip.top="'Áthelyezem a várólistáról a jelentkezők közé'"
                                                @click="removeFromWaitingList($event, slotProps.data)"/>
                                        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                                @click="confirmDeleteApplication(slotProps.data)"/>
                                    </template>
                                </Column>
                                <template #empty>
                                    <div class="my-3">
                                        Nincs egyetlen várólistán szereplő jelentkező sem.
                                    </div>
                                </template>
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="Felhasználók lista">
                            <DataTable :value="event.allUsers" class="mt-1 applications-table"
                                       responsiveLayout="scroll"
                                       v-model:selection="selectedAllUsers"
                                       dataKey="id" :paginator="true" :rows="25" :rowsPerPageOptions="[25, 50, 100]"
                                       :loading="usersLoading">
                                <template #header>
                                    <div
                                        class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                        <h5 class="m-0">Felhasználók <br> <strong><small>Összesen:
                                            {{ event.allUsers.length }}</small></strong></h5>
                                        <div class="width: 100px">
                                            <Button label="Üzenet küldése" icon="pi pi-envelope"
                                                    class="p-button-primary"
                                                    @click="confirmMessageSelected"
                                                    :disabled="!selectedAllUsers || !selectedAllUsers.length"/>
                                        </div>
                                    </div>
                                </template>
                                <Column selectionMode="multiple" headerStyle="width: 1rem"></Column>
                                <Column field="name" header="Felhasználó neve"></Column>
                                <Column field="email" header="E-mail cím"></Column>
                                <template #empty>
                                    <div class="my-3">
                                        Nincs egyetlen felhasználó sem.
                                    </div>
                                </template>
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="Elküldött üzenetek">
                            <DataTable ref="dt" :value="event.messages" :paginator="true" :rows="10" :loading="loading"
                                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                       :rowsPerPageOptions="[5,10,25]" currentPageReportTemplate=""
                                       responsiveLayout="scroll">
                                <template #header>
                                    <div
                                        class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                        <h5 class="m-0">Üzenetek <br> <strong><small>Összesen: {{
                                                event.messages.length
                                            }}</small></strong></h5>
                                    </div>
                                </template>

                                <Column field="id" header="#" :sortable="true" headerStyle="width:2%; min-width:5rem;">
                                    <template #body="slotProps">
                                        <span class="p-column-title">#</span>
                                        {{ slotProps.data.id }}
                                    </template>
                                </Column>
                                <Column field="subject" header="Tárgy" :sortable="true"
                                        headerStyle="width:15%; min-width:10rem;"
                                        :showFilterMenu="false">
                                    <template #body="slotProps">
                                        <span class="p-column-title">Tárgy</span>
                                        {{ slotProps.data.subject }}
                                    </template>
                                </Column>
                                <Column field="email" header="Címzettek" :sortable="true"
                                        headerStyle="width:15%; min-width:10rem;"
                                        :showFilterMenu="false">
                                    <template #body="slotProps">
                                        <span class="p-column-title">Címzettek</span>
                                        {{ slotProps.data.recipients.length }} címzett
                                    </template>
                                </Column>
                                <Column field="createdAt" header="Kiküldés dátuma" :sortable="true"
                                        headerStyle="width:15%; min-width:10rem;">
                                    <template #body="slotProps">
                                        <span class="p-column-title">Kiküldés dátuma</span>
                                        {{ slotProps.data.createdAt }}
                                    </template>
                                </Column>
                                <Column headerStyle="min-width:10rem;">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2"
                                                @click="showMessage(slotProps.data)"/>
                                    </template>
                                </Column>
                                <template #empty>
                                    Nincs egyetlen üzenet sem.
                                </template>
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="Email sablonok">
                            <DataTable :value="event.emailTemplates" class="mt-3" responsiveLayout="scroll">
                                <Column field="id" header="#" :style="{width:'100px'}"></Column>
                                <Column field="name" header="Név"></Column>
                                <Column>
                                    <template #body="slotProps">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                                v-tooltip.top="'Szerkesztés'" @click="editEmailTempalte(slotProps.data)"/>
                                    </template>
                                </Column>
                            </DataTable>
                        </TabPanel>
                    </TabView>
                    <template #footer>
                        <Button label="Bezárom" icon="pi pi-times" class="p-button-primary"
                                @click="closeShowEventDialog"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="eventChooseDialog" :style="{width: '550px'}" header="Esemény létrehozása"
                        :modal="true" @hide="hideEventChooseDialog">
                    <div class="grid">
                        <div class="field col-12">
                            <label for="name">Esemény típusa</label> <br>
                            <Dropdown
                                v-model="eventChoice"
                                :options="eventChoices"
                                optionLabel="label"
                                placeholder="Új esemény típusa"
                                :style="{minWidth: '500px'}"
                            >
                                <template #value="slotProps">
                                    <span v-if="slotProps.value">
                                        {{ slotProps.value.label }}
                                    </span>
                                    <span v-else>
                                        {{ slotProps.placeholder }}
                                    </span>
                                </template>
                            </Dropdown>
                        </div>
                        <div class="field col-12" v-if="eventChoice.value == 1">
                            <Dropdown :options="eventTemplates" optionLabel="label" placeholder="Sablonok"
                                      v-model="eventTemplate" :filter="true" filterPlaceholder="Sablonok"
                                      :style="{minWidth: '500px'}">
                                <template #value="slotProps">
                                    <span v-if="slotProps.value">
                                        {{ slotProps.value.label }}
                                    </span>
                                    <span v-else>
                                        {{ slotProps.placeholder }}
                                    </span>
                                </template>
                            </Dropdown>
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Bezárás" icon="pi pi-times" class="p-button-text"
                                @click="hideEventChooseDialog"/>
                        <Button label="Létrehozás" icon="pi pi-check" class="p-button-success" @click="createNewEvent"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteEventDialog" :style="{width: '550px'}" header="Törlés megerősítése"
                        :modal="true">
                    <div class="flex align-items-center justify-content-center mt-3">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
                        <span>Biztosan törölni szeretné a következő eseményt: <br> <b>{{
                                event.name
                            }}</b>?</span>
                    </div>
                    <template #footer>
                        <Button label="Nem" icon="pi pi-times" class="p-button-text"
                                @click="deleteEventDialog = false"/>
                        <Button label="Igen" icon="pi pi-check" class="p-button-danger" @click="deleteEvent"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteApplicationDialog" :style="{width: '550px'}" header="Törlés megerősítése"
                        :modal="true">
                    <div class="flex align-items-center justify-content-center mt-3">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
                        <span>Biztosan törölni szeretné <b>{{
                                this.application.applier.name
                            }}</b> jelentkezést?</span>
                    </div>
                    <template #footer>
                        <Button label="Nem" icon="pi pi-times" class="p-button-text"
                                @click="deleteApplicationDialog = false"/>
                        <Button label="Igen" icon="pi pi-check" class="p-button-danger" @click="deleteApplication"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteEventsDialog" :style="{width: '550px'}" header="Törlés megerősítése"
                        :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
                        <span>Biztosan törölni szeretné a kiválasztott eseményeket?</span>
                    </div>
                    <template #footer>
                        <Button label="Nem" icon="pi pi-times" class="p-button-text"
                                @click="deleteEventsDialog = false"/>
                        <Button label="Igen" icon="pi pi-check" class="p-button-danger" @click="deleteSelectedEvents"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="messageDialog" :style="{width: '700px'}" header="Üzenet küldése"
                        :modal="true" class="p-fluid">
                    <div class="field">
                        <label for="name">Tárgy</label>
                        <InputText id="subject" v-model.trim="message.subject" required="true"
                                   :class="{'p-invalid': messageSubmitted && !message.subject}"/>
                        <small class="p-invalid" v-if="messageSubmitted && !message.subject">Tárgy megadása
                            kötelező.</small>
                    </div>

                    <div class="field">
                        <label for="message">Üzenet</label>
                        <ckeditor :editor="editor" v-model="messageText" :config="editorConfig"></ckeditor>
                        <small class="p-invalid" v-if="messageSubmitted && !messageText">Üzenet megadása
                            kötelező.</small>
                    </div>

                    <div class="field">
                        <label for="attachment">Melléklet</label>
                        <FileUpload mode="basic" name="attachment" :customUpload="true" @uploader="onUploadAttachment"
                                    :auto="true"
                                    :multiple="true"
                                    :maxFileSize="10000000" class="p-button-outlined p-button-plain"
                                    :chooseLabel="attachment"></FileUpload>
                    </div>

                    <template #footer>
                        <Button label="Mégse" icon="pi pi-times" class="p-button-text"
                                @click="messageDialog = false"/>
                        <Button label="Küldés" icon="pi pi-envelope" class="p-button-primary" @click="sendMessage"
                                :loading="messageLoading"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="showMessageDialog" :style="{width: '800px'}" header="Üzenet adatai"
                        :modal="true" class="p-fluid">
                    <div class="surface-card" v-if="!loading">
                        <ul class="list-none p-0 m-0 border-top-1 border-300">
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">ID</div>
                                <div class="text-900 w-full md:w-5">{{ message.id }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap">
                                <div class="text-500 w-full md:w-5 font-medium">Tárgy</div>
                                <div class="text-900 w-full md:w-5">{{ message.subject }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Kiküldés dátuma</div>
                                <div class="text-900 w-full md:w-5">{{ message.createdAt }}</div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Címzettek</div>
                                <div class="text-900 w-full md:w-5">
                                    {{ message.recipients.map((recipient) => recipient).join(", ") }}
                                </div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap">
                                <div class="text-500 w-full md:w-5 font-medium">Üzenet</div>
                                <div class="text-900 w-full md:w-5">
                                    <span v-html="message.message"></span>
                                </div>
                            </li>
                            <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                                <div class="text-500 w-full md:w-5 font-medium">Melléklet</div>
                                <div class="text-900 w-full md:w-5">
                                    <ul>
                                        <li v-for="(link, index) in message.attachments" :key="index">
                                            <a :href="link" target="_blank">{{ extractLinkLabel(link) }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Dialog>

                <Dialog v-model:visible="deleteApplicationDayDialog" :style="{width: '550px'}"
                        header="Törlés megerősítése"
                        :modal="true">
                    <div class="flex align-items-center justify-content-center mt-3">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
                        <span>Biztosan törölni szeretné <b>{{
                                this.deleteDay
                            }}</b> nap összes jelentkezését?</span>
                    </div>
                    <template #footer>
                        <Button label="Nem" icon="pi pi-times" class="p-button-text"
                                @click="deleteApplicationDayDialog = false"/>
                        <Button label="Igen" icon="pi pi-check" class="p-button-danger" @click="deleteApplicationDay"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteGroupDialog" :style="{width: '550px'}"
                        header="Törlés megerősítése"
                        :modal="true">
                    <div class="flex align-items-center justify-content-center mt-3">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
                        <span>Amennyiben törli a kiválasztott csoportot, úgy a hozzá tartozó jelentkezések is törlésre kerülnek, biztos benne?</span>
                    </div>
                    <template #footer>
                        <Button label="Nem" icon="pi pi-times" class="p-button-text"
                                @click="deleteGroupDialog = false"/>
                        <Button label="Igen" icon="pi pi-check" class="p-button-danger" @click="deleteGroupInBackend"
                                :disabled="deleteGroupDialogLoader" :loading="deleteGroupDialogLoader"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="editEmailTemplateDialog" :breakpoints="{'960px': '75vw', '640px': '100vw'}"
                        :style="{width: '70vw'}"
                        v-bind:header="emailTemplate.name + ' sablon adatai'" @hide="hideEmailTemplateDialog"
                        :modal="true" class="p-fluid">
                    <TabView :style="{minHeight: '500px'}">
                        <TabPanel header="Magyar">
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="contentTop">Tartalom eleje</label>
                                    <ckeditor :editor="editor" v-model="emailTemplate.contentTop" required="true" :config="editorConfig"></ckeditor>
                                    <small class="p-invalid" v-if="emailTemplateSubmitted && !emailTemplate.contentTop">Tartalom eleje megadása
                                        kötelező.</small>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="contentBottom">Tartalom vége</label>
                                    <ckeditor :editor="editor" v-model="emailTemplate.contentBottom" :config="editorConfig"></ckeditor>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Fordítások">
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="contentTopEnglish">Tartalom eleje angolul</label>
                                    <ckeditor :editor="editor" v-model="emailTemplate.contentTopEnglish" required="true" :config="editorConfig"></ckeditor>
                                    <small class="p-invalid" v-if="emailTemplateSubmitted && !emailTemplate.contentTopEnglish">Tartalom eleje angolul megadása
                                        kötelező.</small>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="contentBottomEnglish">Tartalom vége angolul</label>
                                    <ckeditor :editor="editor" v-model="emailTemplate.contentBottomEnglish" :config="editorConfig"></ckeditor>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                    <template #footer>
                        <Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="hideEmailTemplateDialog"/>
                        <Button label="Mentés" icon="pi pi-check" class="p-button-success" @click="saveEmailTemplate"
                                :loading="emailTemplateLoading"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import EventService from "@/service/EventService";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/hu';
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid';
import locale from '@fullcalendar/core/locales/hu';
import MessageService from "@/service/MessageService";
import EmailTemplateService from "@/service/EmailTemplateService";
import ApplicationService from "@/service/ApplicationService";
import { saveAs } from 'file-saver';
import * as dayjs from "dayjs";
import StatService from "@/service/StatService";
import ExcelJS from 'exceljs';

export default {
    components: {
        FullCalendar
    },
    data() {
        return {
            total: 0,
            events: null,
            templates: [],
            image: '',
            attachment: '',
            eventChoice: '',
            actualDay: '',
            eventChooseDialog: false,
            eventDialog: false,
            showEventDialog: false,
            deleteEventDialog: false,
            messageDialog: false,
            showMessageDialog: false,
            deleteEventsDialog: false,
            deleteApplicationDialog: false,
            deleteApplicationDayDialog: false,
            deleteGroupDialog: false,
            deleteGroupDialogLoader: false,
            calendarLoaded: false,
            archiveList: false,
            selectedUsers: null,
            selectedWaitingUsers: null,
            selectedAllUsers: null,
            permissionDenied: true,
            event: {},
            deletableGroupIndex: null,
            groupId: null,
            imageUrl: null,
            imageUploaded: false,
            application: {},
            message: {},
            uniqueDates: [],
            cycleDates: [],
            extraDates: [],
            excludeDates: [],
            sentMessages: [],
            eventTemplates: [],
            eventTemplate: '',
            cycleDateStart: '',
            cycleDateEnd: '',
            messageText: '',
            deleteDay: '',
            downloadDay: '',
            groups: [
                // {
                //     dailyMaximumTimeSlot: '',
                //     weeklyMaximumTimeSlot: '',
                //     minimumAgeLimit: '',
                //     maximumAgeLimit: '',
                //     maximumApplicationsCount: '',
                //     maximumWaitingListApplicationsCount: '',
                //     waitingListMethodOfEntry: '',
                // }
            ],
            emailTemplates: [],
            emailTemplate: {},
            editEmailTemplateDialog: false,
            emailTemplateSubmitted: false,
            emailTemplateLoading: false,
            emailTemplateService: null,
            selectedEvents: null,
            filters: {},
            messageSubmitted: false,
            submitted: false,
            loading: true,
            messageLoading: false,
            applicationLoading: false,
            waitingListLoading: false,
            usersLoading: false,
            eventService: null,
            messageService: null,
            applicationService: null,
            editor: ClassicEditor,
            description: '',
            shortDescription: '',
            descriptionEnglish: '',
            shortDescriptionEnglish: '',
            calendarOptions: {
                plugins: [timeGridPlugin, interactionPlugin, dayGridPlugin],
                locale: locale,
                initialView: 'timeGridWeek',
                selectable: true,
                events: [],
            },
            editorConfig: {
                toolbar: {
                    items: [
                        'heading', '|',
                        // 'alignment', '|',
                        'bold', 'italic',
                        'link', '|',
                        'outdent', 'indent', '|',
                        'bulletedList', 'numberedList', '|',
                        'undo', 'redo'
                    ],
                    shouldNotGroupWhenFull: true
                }
            },
            dateTypes: [
                {
                    label: 'Egyedi', value: '0'
                },
                {
                    label: 'Ciklikus', value: '1'
                }
            ],
            intervals: [
                {
                    label: '30 perc', value: '30'
                },
                {
                    label: '45 perc', value: '45'
                },
                {
                    label: '60 perc', value: '60'
                },
                {
                    label: '90 perc', value: '90'
                },
                {
                    label: '120 perc', value: '120'
                },
                {
                    label: '180 perc', value: '180'
                },
                {
                    label: '4 óra', value: '240'
                },
                {
                    label: '5 óra', value: '300'
                },
                {
                    label: '6 óra', value: '360'
                },
                {
                    label: '7 óra', value: '420'
                },
                {
                    label: '8 óra', value: '480'
                }
            ],
            visibleDate: [
                {
                    label: '1 hétre előre', value: '1'
                },
                {
                    label: '2 hétre előre', value: '2'
                },
                {
                    label: '1 hónapra előre', value: '3'
                },
                {
                    label: 'Összes időpont', value: '4'
                },
                {
                    label: 'Párhuzamos jelentkezés', value: '5'
                },
            ],
            weekCloseDays: [
                {
                    label: 'Hétfő', value: '1'
                },
                {
                    label: 'Kedd', value: '2'
                },
                {
                    label: 'Szerda', value: '3'
                },
                {
                    label: 'Csütörtök', value: '4'
                },
                {
                    label: 'Péntek', value: '5'
                },
                {
                    label: 'Szombat', value: '6'
                },
                {
                    label: 'Vasárnap', value: '7'
                },
            ],
            showActualWeek: [
                {
                    label: 'Igen', value: '1',
                },
                {
                    label: 'Nem', value: '0',
                }
            ],
            whenCheckChildAge: [
                {
                    label: 'Jelentkezés napján', value: '1',
                },
                {
                    label: 'Az esemény első napján', value: '2',
                }
            ],
            waitingListMethodOfEntry: [
                {
                    label: 'Manuális', value: '0',
                },
                {
                    label: 'Automatikus', value: '1',
                }
            ],
            ageLimitMetric: [
                {
                    label: 'év', value: '0',
                },
                {
                    label: 'hónap', value: '1',
                },
                {
                    label: 'hét', value: '2',
                }
            ],
            eventChoices: [
                {
                    label: 'Új üres esemény', value: '0',
                },
                {
                    label: 'Új esemény sablonból', value: '1',
                }
            ],
            actualDays: [],
            statService: null,
        }
    },
    created() {
        this.user = JSON.parse(localStorage.getItem('userData'));
        this.eventService = new EventService();
        this.messageService = new MessageService();
        this.applicationService = new ApplicationService();
        this.statService = new StatService();
        this.emailTemplateService = new EmailTemplateService();
        this.initFilters();

        if (window.innerWidth <= 800) {
            this.calendarOptions.initialView = 'dayGridDay';
        } else {
            this.calendarOptions.initialView = 'timeGridWeek';
        }
    },
    mounted() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.permissionDenied = user.role === 1;

        this.getAll()
        this.getTemplateList()
    },
    methods: {
        openImage() {
            window.open(this.imageUrl, '_blank');
        },
        deleteImage(event) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Biztosan szeretnéd törölni a képet?',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Igen',
                rejectLabel: 'Nem',
                accept: () => {
                    this.eventService.deleteImage(this.event.id).then(() => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sikeres művelet!',
                            detail: 'A kép törlése sikeres volt.',
                            life: 5000
                        });

                        this.imageUrl = null;
                        this.imageUploaded = false;
                    }).catch(e => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Sikertelen művelet!",
                            detail: e.response.data.error,
                            life: 6000,
                        });
                    });
                }
            });
        },
        onUpload(event) {
            this.image = this.$options.filters.truncate(event.files[0].name, 30);
            this.imageUrl = URL.createObjectURL(event.files[0]);
            this.imageUploaded = false;
            this.createBase64Image(event.files[0]);
        },
        createBase64Image: function (FileObject) {
            const reader = new FileReader();

            reader.onload = (event) => {
                this.event.image = event.target.result;
            }

            reader.readAsDataURL(FileObject);
        },
        onUploadAttachment(event) {
            this.attachment = '';
            delete this.message.attachment;
            const files = event.files.slice(0, 2);
            const base64Datas = [];
            const fileNames = [];
            files.forEach(file => {
                fileNames.push(this.$options.filters.truncate(file.name, 30));
                const reader = new FileReader();
                reader.onload = () => {
                    const base64Data = reader.result;
                    base64Datas.push(base64Data);
                };
                reader.readAsDataURL(file);
            });
            this.attachment = fileNames.join(", ");
            this.message.attachment = base64Datas;
        },
        getAll() {
            this.loading = true;

            this.eventService.getAll().then(response => {
                this.total = response.data.total
                this.events = response.data.data
                this.loading = false;
            });
        },
        getAllArchiveList() {
            this.loading = true;

            this.eventService.getAllArchiveList().then(response => {
                this.total = response.data.total
                this.events = response.data.data
                this.loading = false;
            });
        },
        getTemplateList() {
            this.eventService.getTemplateList().then(response => {
                this.eventTemplates = response.data.data
            });
        },
        openNew() {
            this.eventChooseDialog = true;
            this.eventTemplates = [];
            this.getTemplateList();
        },
        createNewEvent() {
            if (this.eventTemplate && this.eventChoice.value === '1') {
                this.initTemplateEvent(this.eventTemplate.value);
            } else {
                this.initNewEvent();
                this.eventDialog = true;
            }
        },
        showMessage(message) {
            this.message = {...message};
            this.showMessageDialog = true;
        },
        show(event) {
            this.eventService.get(event.id).then(response => {
                this.event = response.data;
                let outThis = this;

                this.calendarOptions.eventTimeFormat = {
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: false
                };

                this.calendarOptions.events = [];
                this.calendarOptions.events = this.event.calendarApplies;
                this.calendarOptions.slotMinTime = "08:00:00";
                this.calendarOptions.slotMaxTime = "19:00:00";

                this.calendarOptions.dayHeaderContent = function (arg) {
                    let date = arg.date;
                    let date2 = String(date.getMonth() + 1).padStart(2, '0') + '. ' + String(date.getDate()).padStart(2, '0');
                    let day = new Date(date).toLocaleString('hu-HU', {weekday: 'long'});

                    let button = document.createElement('button')
                    let downloadButton = document.createElement('button')
                    let p = document.createElement('p')

                    p.innerHTML = date2 + '<br>' + day

                    let arrayOfDomNodes = [p]

                    if (!outThis.event.simplifiedApplication) {
                        downloadButton.innerHTML = '<span class="pi pi-download p-button-icon"></span>'
                        downloadButton.icon = "pi pi-plus"
                        downloadButton.className = 'p-button p-button-success p-button-icon-only day-delete mr-2'
                        downloadButton.type = 'button'

                        downloadButton.addEventListener("click", function () {
                            outThis.downloadDay = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')
                            outThis.downloadDailyApplicants();
                        });

                        button.innerHTML = '<span class="pi pi-trash p-button-icon"></span>'
                        button.className = 'p-button p-button-danger p-button-icon-only day-delete'
                        button.type = 'button'

                        button.addEventListener("click", function () {
                            outThis.deleteDay = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')
                            outThis.selectDeleteDay();
                        });

                        if (!outThis.event.withoutChildrenApplication) {
                            arrayOfDomNodes = [p, downloadButton, button]
                        } else {
                            arrayOfDomNodes = [p, button]
                        }
                    }

                    return {domNodes: arrayOfDomNodes}
                }

                let date = new Date();
                this.calendarOptions.initialDate = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')
                this.showEventDialog = true;

                setTimeout(() => this.calendarLoaded = true, 500);
            })
        },
        closeShowEventDialog() {
            this.showEventDialog = false;
            this.calendarLoaded = false;
        },
        hideDialog() {
            this.eventDialog = false;
            this.submitted = false;

            this.event = {};
            this.uniqueDates = [];
            this.cycleDates = [];
            this.extraDates = [];
            this.excludeDates = [];
        },
        hideEventChooseDialog() {
            this.eventChooseDialog = false
            this.eventChoice = '';
            this.eventTemplate = '';
            this.getTemplateList();
        },
        changeDateType(event) {
            this.event.dateTypeNumber = event.value.value;

            this.uniqueDates = [];
            this.cycleDates = [];
            this.extraDates = [];
            this.excludeDates = [];

            this.addUniqueOrCycleDate();
        },
        // handleEventClick(clickInfo) {
        //     console.log(clickInfo);
        //
        //     if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        //         clickInfo.event.remove()
        //     }
        // },
        addUniqueOrCycleDate() {
            if (this.event.dateTypeNumber == 0) {
                this.uniqueDates.push({
                    date: '',
                    open: '',
                    close: '',
                });
            } else {

                this.cycleDates.push({
                    day: 'monday',
                    dayText: 'Hétfő',
                    openingHours: [{
                        open: '',
                        close: '',
                    }]
                }, {
                    day: 'tuesday',
                    dayText: 'Kedd',
                    openingHours: [{
                        open: '',
                        close: '',
                    }]
                }, {
                    day: 'wednesday',
                    dayText: 'Szerda',
                    openingHours: [{
                        open: '',
                        close: '',
                    }]
                }, {
                    day: 'thursday',
                    dayText: 'Csütörtök',
                    openingHours: [{
                        open: '',
                        close: '',
                    }]
                }, {
                    day: 'friday',
                    dayText: 'Péntek',
                    openingHours: [{
                        open: '',
                        close: '',
                    }]
                }, {
                    day: 'saturday',
                    dayText: 'Szombat',
                    openingHours: [{
                        open: '',
                        close: '',
                    }]
                }, {
                    day: 'sunday',
                    dayText: 'Vasárnap',
                    openingHours: [{
                        open: '',
                        close: '',
                    }]
                });
            }
        },
        addCycleDateOpeningHour(index) {
            this.cycleDates[index].openingHours.push({
                open: '',
                close: '',
            });
        },
        addExtraDate() {
            this.extraDates.push({
                date: '',
                open: '',
                close: '',
                type: 1,
            });
        },
        addExcludeDate() {
            this.excludeDates.push({
                date: '',
                open: '',
                close: '',
                type: 0,
            });
        },
        addGroup() {
            this.groups.push({
                dailyMaximumTimeSlot: '',
                weeklyMaximumTimeSlot: '',
                weeklyMaximumDays: '',
                minimumAgeLimit: '',
                maximumAgeLimit: '',
                maximumApplicationsCount: '',
                maximumWaitingListApplicationsCount: '',
                waitingListMethodOfEntry: '',
            });
        },
        selectDeleteDay() {
            this.deleteApplicationDayDialog = true;
        },
        deleteUniqueDate(index) {
            this.uniqueDates.splice(index, 1);
        },
        deleteExcludeDate(index) {
            this.excludeDates.splice(index, 1);
        },
        deleteExtraDate(index) {
            this.extraDates.splice(index, 1);
        },
        deleteCycleDateOpeningHour(index, j) {
            this.cycleDates[index].openingHours.splice(j, 1);
        },
        deleteGroup(index, groupId) {
            this.deletableGroupIndex = index;

            if (groupId) {
                this.deleteGroupDialog = true;
                this.groupId = groupId;
            } else {
                this.groups.splice(this.deletableGroupIndex, 1);
            }
        },
        deleteGroupInBackend() {
            this.deleteGroupDialogLoader = true;

            this.eventService.deleteGroup(this.groupId).then(() => {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Sikeres művelet!',
                    detail: 'A csoport törlése sikeres volt.',
                    life: 5000
                });

                this.groups.splice(this.deletableGroupIndex, 1);
                this.deleteGroupDialog = false;
                this.deleteGroupDialogLoader = false;
            }).catch(e => {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: e.response.data.error,
                    life: 6000,
                });
                this.deleteGroupDialogLoader = false;
            });
        },
        getArchiveList() {
            this.archiveList = true;
            this.getAllArchiveList();
        },
        getActiveList() {
            this.archiveList = false;
            this.getAll();
        },
        setToActive(event, data) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Biztosan szeretné aktiválni az eseményt?',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Igen',
                rejectLabel: 'Nem',
                accept: () => {
                    this.loading = true;

                    this.eventService.archive(data.id, {
                        isArchive: false
                    }).then(() => {
                        this.archiveList = false;
                        this.getAll()

                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sikeres művelet!',
                            detail: 'Az esemény aktiválása sikeres volt.',
                            life: 5000
                        });

                        this.loading = false;
                    }).catch(e => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Sikertelen művelet!",
                            detail: e.response.data.error,
                            life: 6000,
                        });

                        this.loading = false;
                    });
                }
            });
        },
        setToArchive(event, data) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Biztosan szeretné archiválni az eseményt?',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Igen',
                rejectLabel: 'Nem',
                accept: () => {
                    this.loading = true;

                    this.eventService.archive(data.id, {
                        isArchive: true
                    }).then(() => {
                        this.getAll()

                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sikeres művelet!',
                            detail: 'Az esemény archiválása sikeres volt.',
                            life: 5000
                        });

                        this.loading = false;
                    }).catch(e => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Sikertelen művelet!",
                            detail: e.response.data.error,
                            life: 6000,
                        });

                        this.loading = false;
                    });
                }
            });
        },
        saveEvent() {
            this.submitted = true;

            if (
                !this.event.name ||
                !this.description ||
                !this.event.showActualWeek ||
                !(this.event.visibleDate && this.event.visibleDate.value) ||
                !this.event.dateTypeNumber ||
                !this.event.visibilityStart ||
                !this.event.visibilityEnd ||
                !this.event.interval ||
                !this.event.whenCheckChildAge ||
                !this.event.weekCloseDay ||
                !this.event.weekCloseTime
            ) {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: 'Kérlek tölts ki minden kötelező mezőt!',
                    life: 6000,
                });

                return
            }

            let error = 0;

            if (this.uniqueDates.length > 0) {
                this.uniqueDates.map((uniqueDate, key) => {
                    let openElement = document.getElementById("uniqueDate-open-" + key);
                    let closeElement = document.getElementById("uniqueDate-close-" + key);

                    if (!uniqueDate.open) {
                        error = 1;
                        openElement.classList.add("p-invalid");
                        return;
                    }

                    if (!uniqueDate.close) {
                        error = 1;
                        closeElement.classList.add("p-invalid");
                        return;
                    }

                    let open = uniqueDate.open instanceof Date ? uniqueDate.open.getTime() : new Date(this.getActualDate() + " " + uniqueDate.open).getTime()
                    let close = uniqueDate.close instanceof Date ? uniqueDate.close.getTime() : new Date(this.getActualDate() + " " + uniqueDate.close).getTime();

                    if (open > close) {
                        openElement.classList.add("p-invalid");
                        closeElement.classList.add("p-invalid");
                        error = 1;
                    } else {
                        openElement.classList.remove("p-invalid");
                        closeElement.classList.remove("p-invalid");
                    }

                    if (close < open) {
                        openElement.classList.add("p-invalid");
                        closeElement.classList.add("p-invalid");
                        error = 1;
                    } else {
                        openElement.classList.remove("p-invalid");
                        closeElement.classList.remove("p-invalid");
                    }
                })
            }

            if (this.extraDates.length > 0) {
                this.extraDates.map((extraDate, key) => {
                    let openElement = document.getElementById("extraDate-open-" + key);
                    let closeElement = document.getElementById("extraDate-close-" + key);

                    if (!extraDate.open) {
                        error = 1;
                        openElement.classList.add("p-invalid");
                        return;
                    }

                    if (!extraDate.close) {
                        error = 1;
                        closeElement.classList.add("p-invalid");
                        return;
                    }

                    let open = extraDate.open instanceof Date ? extraDate.open.getTime() : new Date(this.getActualDate() + " " + extraDate.open).getTime()
                    let close = extraDate.close instanceof Date ? extraDate.close.getTime() : new Date(this.getActualDate() + " " + extraDate.close).getTime();

                    if (open > close) {
                        openElement.classList.add("p-invalid");
                        closeElement.classList.add("p-invalid");
                        error = 1;
                    } else {
                        openElement.classList.remove("p-invalid");
                        closeElement.classList.remove("p-invalid");
                    }

                    if (close < open) {
                        openElement.classList.add("p-invalid");
                        closeElement.classList.add("p-invalid");
                        error = 1;
                    } else {
                        openElement.classList.remove("p-invalid");
                        closeElement.classList.remove("p-invalid");
                    }
                })
            }

            if (this.excludeDates.length > 0) {
                this.excludeDates.map((excludeDate, key) => {
                    let openElement = document.getElementById("excludeDate-open-" + key);
                    let closeElement = document.getElementById("excludeDate-close-" + key);

                    if (!excludeDate.open) {
                        error = 1;
                        openElement.classList.add("p-invalid");
                        return;
                    }

                    if (!excludeDate.close) {
                        error = 1;
                        closeElement.classList.add("p-invalid");
                        return;
                    }

                    let open = excludeDate.open instanceof Date ? excludeDate.open.getTime() : new Date(this.getActualDate() + " " + excludeDate.open).getTime()
                    let close = excludeDate.close instanceof Date ? excludeDate.close.getTime() : new Date(this.getActualDate() + " " + excludeDate.close).getTime();

                    if (open > close) {
                        openElement.classList.add("p-invalid");
                        closeElement.classList.add("p-invalid");
                        error = 1;
                    } else {
                        openElement.classList.remove("p-invalid");
                        closeElement.classList.remove("p-invalid");
                    }

                    if (close < open) {
                        openElement.classList.add("p-invalid");
                        closeElement.classList.add("p-invalid");
                        error = 1;
                    } else {
                        openElement.classList.remove("p-invalid");
                        closeElement.classList.remove("p-invalid");
                    }
                })
            }

            if (Object.keys(this.cycleDates).length > 0) {
                if (!this.cycleDateStart || !this.cycleDateEnd) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Sikertelen művelet!",
                        detail: 'Kérlek pótold az időpontok fülön hiányzó adatokat (ciklus kezdete és vége)!',
                        life: 8000,
                    });

                    return;
                }

                let isUpdated = false;
                let cycleDates = this.cycleDates;

                if (typeof this.cycleDates === 'object') {
                    cycleDates = Object.entries(this.cycleDates);
                    isUpdated = true;
                }

                cycleDates.map((cycleDate) => {
                    if (isUpdated) {
                        cycleDate = cycleDate[1];
                    }

                    cycleDate.openingHours.map((openingHour, j) => {
                        let openElement = document.getElementById('cycleDate-open-' + cycleDate.day + '-' + j);
                        let closeElement = document.getElementById('cycleDate-close-' + cycleDate.day + '-' + j);

                        if (!openElement) {
                            openElement = document.getElementById('cycleDate-open-' + j + '-' + j);
                        }

                        if (!closeElement) {
                            closeElement = document.getElementById('cycleDate-close-' + j + '-' + j);
                        }

                        let open = openingHour.open instanceof Date ? openingHour.open.getTime() : new Date(this.getActualDate() + " " + openingHour.open).getTime()
                        let close = openingHour.close instanceof Date ? openingHour.close.getTime() : new Date(this.getActualDate() + " " + openingHour.close).getTime();

                        if (open > close) {
                            openElement.classList.add("p-invalid");
                            closeElement.classList.add("p-invalid");
                            error = 1;
                        } else {
                            openElement.classList.remove("p-invalid");
                            closeElement.classList.remove("p-invalid");
                        }

                        if (close < open) {
                            openElement.classList.add("p-invalid");
                            closeElement.classList.add("p-invalid");
                            error = 1;
                        } else {
                            openElement.classList.remove("p-invalid");
                            closeElement.classList.remove("p-invalid");
                        }
                    })
                })
            }

            if (error === 1) {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: 'Kérlek javítsd az időpontok fülön található hibákat! Nyitás illetve zárás időpontja kötelező, és nyitás időpontja nem lehet későbbi mint a zárás időpontja, és a zárás időponta sem lehet korábbi mint a nyitás időpontja.',
                    life: 10000,
                });

                return;
            }

            let event = {
                "name": this.event.name,
                "nameEnglish": this.event.nameEnglish,
                "description": this.description ? this.description : '',
                "shortDescription": this.shortDescription ? this.shortDescription : '',
                "descriptionEnglish": this.descriptionEnglish ? this.descriptionEnglish : '',
                "shortDescriptionEnglish": this.shortDescriptionEnglish ? this.shortDescriptionEnglish : '',
                "simplifiedApplication": this.event.simplifiedApplication ? this.event.simplifiedApplication : false,
                "withoutChildrenApplication": this.event.withoutChildrenApplication ? this.event.withoutChildrenApplication : false,
                "childrenSelectorOff": this.event.childrenSelectorOff ? this.event.childrenSelectorOff : false,
                "needHungarianLanguage": this.event.needHungarianLanguage ? this.event.needHungarianLanguage : false,
                "allWeekApplication": this.event.allWeekApplication ? this.event.allWeekApplication : false,
                "wholeWeekApplication": this.event.wholeWeekApplication ? this.event.wholeWeekApplication : false,
                "showActualWeek": this.event.showActualWeek ? parseInt(this.event.showActualWeek.value) : 1,
                "visibleDates": this.event.visibleDate.value,
                "dateType": this.event.dateTypeNumber,
                "visibilityStart": this.event.visibilityStart instanceof Date ?
                    new Intl.DateTimeFormat('ko-KR').format(this.event.visibilityStart) : this.event.visibilityStart,
                "visibilityEnd": this.event.visibilityEnd instanceof Date ?
                    new Intl.DateTimeFormat('ko-KR').format(this.event.visibilityEnd) : this.event.visibilityEnd,
                "interval": this.event.interval ? parseInt(this.event.interval.value) : 30,
                "checkChildrenAge": this.event.whenCheckChildAge ? parseInt(this.event.whenCheckChildAge.value) : 1,
                "weekCloseDay": this.event.weekCloseDay.value,
                "maximumApplicationsCount": parseInt(this.event.maximumApplicationsCount),
                "maximumWaitingListApplicationsCount": parseInt(this.event.maximumWaitingListApplicationsCount),
                "maximumAcclimatizationCount": parseInt(this.event.maximumAcclimatizationCount),
                "template": this.event.template,
                "rememberNotify": 1,
                "uniqueDates": [],
                "cycleDates": [],
                "extraAndExcludeDates": [],
                "groups": [],
                "emailTemplates": [],
                "dailyMaximumTimeSlot": parseInt(this.event.dailyMaximumTimeSlot),
                "weeklyMaximumTimeSlot": parseInt(this.event.weeklyMaximumTimeSlot),
                "weeklyMaximumDays": parseInt(this.event.weeklyMaximumDays),
                "waitingListMethodOfEntry": this.event.waitingListMethodOfEntry ? parseInt(this.event.waitingListMethodOfEntry.value) : 0,
                "disableApplicationWhenUserDisabled": this.event.disableApplicationWhenUserDisabled ? this.event.disableApplicationWhenUserDisabled : false,
            }

            event.weekCloseTime = this.event.weekCloseTime instanceof Date ? new Intl.DateTimeFormat('default', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: false
            }).format(this.event.weekCloseTime) : this.event.weekCloseTime;

            event.weekCloseTime = event.weekCloseTime.replace(' AM', '');
            event.weekCloseTime = event.weekCloseTime.replace('AM', '');
            event.weekCloseTime = event.weekCloseTime.replace('PM', '');
            event.weekCloseTime = event.weekCloseTime.replace(' PM', '');

            if (this.uniqueDates) {
                this.uniqueDates.map((uniqueDate) => {
                    let date = uniqueDate.date instanceof Date ?
                        new Intl.DateTimeFormat('ko-KR').format(uniqueDate.date) : uniqueDate.date;

                    let open = uniqueDate.open instanceof Date ? new Intl.DateTimeFormat('default', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false
                    }).format(uniqueDate.open) : uniqueDate.open;

                    let close = uniqueDate.close instanceof Date ? new Intl.DateTimeFormat('default', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false
                    }).format(uniqueDate.close) : uniqueDate.close;

                    open = open.replace(' AM', '');
                    open = open.replace('AM', '');
                    open = open.replace('PM', '');
                    open = open.replace(' PM', '');

                    close = close.replace(' AM', '');
                    close = close.replace('AM', '');
                    close = close.replace('PM', '');
                    close = close.replace(' PM', '');

                    event.uniqueDates.push({
                        "date": date,
                        "open": date + ' ' + open,
                        "close": date + ' ' + close
                    })
                })
            }

            if (this.extraDates) {
                this.extraDates.map((extraDate) => {
                    let date = extraDate.date instanceof Date ?
                        new Intl.DateTimeFormat('ko-KR').format(extraDate.date) : extraDate.date;

                    let open = extraDate.open instanceof Date ? new Intl.DateTimeFormat('default', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false
                    }).format(extraDate.open) : extraDate.open;

                    let close = extraDate.close instanceof Date ? new Intl.DateTimeFormat('default', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false
                    }).format(extraDate.close) : extraDate.close;

                    open = open.replace(' AM', '');
                    open = open.replace('AM', '');
                    open = open.replace('PM', '');
                    open = open.replace(' PM', '');

                    close = close.replace(' AM', '');
                    close = close.replace('AM', '');
                    close = close.replace('PM', '');
                    close = close.replace(' PM', '');

                    if (
                        date && open && close
                    ) {
                        event.extraAndExcludeDates.push({
                            "date": date,
                            "open": date + ' ' + open,
                            "close": date + ' ' + close,
                            "type": 1,
                        })
                    }
                })
            }

            if (this.excludeDates) {
                this.excludeDates.map((excludeDate) => {
                    let date = excludeDate.date instanceof Date ?
                        new Intl.DateTimeFormat('ko-KR').format(excludeDate.date) : excludeDate.date;

                    let open = excludeDate.open instanceof Date ? new Intl.DateTimeFormat('default', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false
                    }).format(excludeDate.open) : excludeDate.open;

                    let close = excludeDate.close instanceof Date ? new Intl.DateTimeFormat('default', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false
                    }).format(excludeDate.close) : excludeDate.close;

                    open = open.replace(' AM', '');
                    open = open.replace('AM', '');
                    open = open.replace('PM', '');
                    open = open.replace(' PM', '');

                    close = close.replace(' AM', '');
                    close = close.replace('AM', '');
                    close = close.replace('PM', '');
                    close = close.replace(' PM', '');

                    if (
                        date && open && close
                    ) {
                        event.extraAndExcludeDates.push({
                            "date": date,
                            "open": date + ' ' + open,
                            "close": date + ' ' + close,
                            "type": 0,
                        })
                    }
                })
            }

            if (this.cycleDates) {
                let isUpdated = false;
                let cycleDates = this.cycleDates;

                if (typeof this.cycleDates === 'object') {
                    cycleDates = Object.entries(this.cycleDates);
                    isUpdated = true;
                }

                event.cycleDateStart = this.cycleDateStart instanceof Date ?
                    new Intl.DateTimeFormat('ko-KR').format(this.cycleDateStart) : this.cycleDateStart;
                event.cycleDateEnd = this.cycleDateEnd instanceof Date ?
                    new Intl.DateTimeFormat('ko-KR').format(this.cycleDateEnd) : this.cycleDateEnd;

                event.cycleDates = cycleDates.map((cycleDate) => {
                    if (isUpdated) {
                        cycleDate = cycleDate[1];
                    }

                    return cycleDate.openingHours.map((openingHour) => {
                        let newObj = {};

                        newObj["day"] = cycleDate.day;

                        newObj["open"] = openingHour.open instanceof Date ? new Intl.DateTimeFormat('default', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: false
                        }).format(openingHour.open) : openingHour.open;

                        newObj["close"] = openingHour.close instanceof Date ? new Intl.DateTimeFormat('default', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: false
                        }).format(openingHour.close) : openingHour.close;

                        if (newObj["open"]) {
                            newObj["open"] = newObj["open"].replace(' AM', '');
                            newObj["open"] = newObj["open"].replace('AM', '');
                            newObj["open"] = newObj["open"].replace('PM', '');
                            newObj["open"] = newObj["open"].replace(' PM', '');
                        }

                        if (newObj["close"]) {
                            newObj["close"] = newObj["close"].replace(' AM', '');
                            newObj["close"] = newObj["close"].replace('AM', '');
                            newObj["close"] = newObj["close"].replace('PM', '');
                            newObj["close"] = newObj["close"].replace(' PM', '');
                        }

                        return newObj;
                    })
                })
            }

            if (this.groups) {
                this.groups.map((group) => {
                    event.groups.push({
                        "id": group.id ? group.id : null,
                        "dailyMaximumTimeSlot": parseInt(group.dailyMaximumTimeSlot),
                        "weeklyMaximumTimeSlot": parseInt(group.weeklyMaximumTimeSlot),
                        "weeklyMaximumDays": parseInt(group.weeklyMaximumDays),
                        "minimumAgeLimit": parseInt(group.minimumAgeLimit),
                        "minimumAgeLimitMetric": group.minimumAgeLimitMetric ? parseInt(group.minimumAgeLimitMetric.value) : 0,
                        "maximumAgeLimit": parseInt(group.maximumAgeLimit),
                        "maximumAgeLimitMetric": group.maximumAgeLimitMetric ? parseInt(group.maximumAgeLimitMetric.value) : 0,
                        "maximumApplicationsCount": parseInt(group.maximumApplicationsCount),
                        "maximumWaitingListApplicationsCount": parseInt(group.maximumWaitingListApplicationsCount),
                        "waitingListMethodOfEntry": group.waitingListMethodOfEntry ? parseInt(group.waitingListMethodOfEntry.value) : 0,
                        "maximumAcclimatizationCount": event.maximumAcclimatizationCount ? parseInt(event.maximumAcclimatizationCount) : 0,
                    })
                })
            }

            if (this.emailTemplates) {
                this.emailTemplates.map((emailTemplate) => {
                    event.emailTemplates.push({
                        "id": emailTemplate.id ? emailTemplate.id : null,
                        "slug": emailTemplate.slug,
                        "name": emailTemplate.name,
                        "contentTop": emailTemplate.contentTop,
                        "contentTopEnglish": emailTemplate.contentTopEnglish,
                        "contentBottom": emailTemplate.contentBottom,
                        "contentBottomEnglish": emailTemplate.contentBottomEnglish
                    })
                })
            }

            if (this.image) {
                event.image = this.event.image;
            }

            if (this.event.name.trim()) {
                if (this.event.id) {
                    this.loading = true;

                    this.eventService.update(this.event.id, event).then(() => {
                        this.eventDialog = false;

                        this.initNewEvent();
                        this.getAll()
                        this.getTemplateList()

                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sikeres művelet!',
                            detail: 'Az esemény adatai sikeresen módosultak.',
                            life: 5000
                        });
                    }).catch(e => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Sikertelen művelet!",
                            detail: e.response.data.error,
                            life: 6000,
                        });

                        this.loading = false;
                    });
                } else {
                    this.loading = true;

                    this.eventService.create(event).then(() => {
                        this.eventDialog = false;
                        this.event = {};

                        this.getAll()
                        this.getTemplateList()

                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sikeres művelet!',
                            detail: 'Az új esemény mentése sikeres volt.',
                            life: 5000
                        });
                    }).catch(e => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Sikertelen művelet!",
                            detail: e.response.data.error,
                            life: 6000,
                        });

                        this.loading = false;
                    });
                }
            }
        },
        edit(event) {
            this.cycleDates = [];
            this.uniqueDates = [];
            this.extraDates = [];
            this.excludeDates = [];
            this.groups = [];
            this.emailTemplates = [];
            this.cycleDateStart = '';
            this.cycleDateEnd = '';
            this.description = '';
            this.descriptionEnglish = '';
            this.shortDescription = '';
            this.shortDescriptionEnglish = '';
            this.image = '';
            this.imageUrl = null;

            this.eventService.get(event.id).then(response => {
                this.event = response.data;

                if (this.event.image) {
                    this.imageUrl = this.event.image;
                    this.imageUploaded = true;
                }

                if (this.event.uniqueDates.length > 0) {
                    this.uniqueDates = this.event.uniqueDates;
                }

                if (this.event.cycleDates) {
                    this.cycleDates = this.event.cycleDates;

                    if (this.event.extraDates.length > 0) {
                        this.extraDates = this.event.extraDates;
                    }

                    if (this.event.excludeDates.length > 0) {
                        this.excludeDates = this.event.excludeDates;
                    }
                }

                if (this.event.groups.length > 0) {
                    this.groups = this.event.groups;
                }

                if (this.event.emailTemplates.length > 0) {
                    this.emailTemplates = this.event.emailTemplates;
                }

                this.description = this.event.description;
                this.descriptionEnglish = this.event.descriptionEnglish;
                this.cycleDateStart = this.event.cycleDateStart;
                this.cycleDateEnd = this.event.cycleDateEnd;
                this.shortDescription = this.event.shortDescription;
                this.shortDescriptionEnglish = this.event.shortDescriptionEnglish;

                this.eventDialog = true;
            });
        },
        confirmDelete(event) {
            this.event = event;
            this.deleteEventDialog = true;
        },
        confirmDeleteApplication(application) {
            this.application = application;
            this.deleteApplicationDialog = true;
        },
        deleteEvent() {
            this.delete();
            this.deleteEventDialog = false;
        },
        deleteApplication() {
            this.deleteApplicationOperation();
            this.deleteApplicationDialog = false;
        },
        deleteApplicationDay() {
            this.deleteApplicationDayDialog = false;
            this.calendarLoaded = false;

            this.applicationService.removeDay({
                eventId: this.event.id,
                day: this.deleteDay
            }).then((response) => {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Sikeres művelet!',
                    detail: 'Az összes jelentkezés törlése sikeres volt.',
                    life: 5000
                });

                this.calendarOptions.events = [];
                this.calendarOptions.events = response.data.calendarApplies;
                this.event = response.data;
                this.deleteDay = '';
                this.actualDay = '';

                setTimeout(() => this.calendarLoaded = true, 500);
            }).catch(e => {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: e.response.data.error,
                    life: 6000,
                });

                this.applicationLoading = false;
            });
        },
        async downloadDailyApplicants() {
            const response = await this.statService.exportApplicantsByDay({
                eventId: this.event.id,
                date: this.downloadDay,
                dateFrom: this.downloadDay + " 00:00:01",
                dateTo: this.downloadDay + " 23:59:59",
            });

            const today = dayjs().format('YYYY-MM-DD');
            const filename = `${today}_jelentkezok.xlsx`;

            saveAs(response.data, filename);
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.events.length; i++) {
                if (this.events[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        async exportToExcel() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1');
            worksheet.columns = [
                { header: '#', key: 'id', width: 10 },
                { header: 'Név', key: 'name', width: 50 },
                { header: 'Típus', key: 'dateType', width: 10 },
                { header: 'Sablon-e', key: 'template', width: 10 },
                { header: 'Létrehozás dátuma', key: 'createdAt', width: 20 }
            ];

            const selectedProperties = worksheet.columns.map(column => column.key);
            const data = this.events === null ? [] : this.events;
            const modifiedData = data.map(obj =>
                selectedProperties.reduce((acc, prop) => {
                acc[prop] = obj[prop];
                return acc;
                }, {})
            );

            modifiedData.forEach(row => {
                worksheet.addRow(Object.values(row));
            });

            const trueFalseColumnIndex = selectedProperties.indexOf('template');
            if (trueFalseColumnIndex !== -1) {
                worksheet.getColumn(trueFalseColumnIndex + 1).eachCell((cell, rowNumber) => {
                if (rowNumber > 1) {
                    cell.value = cell.value ? 'Igen' : 'Nem';
                }
                });
            }

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const today = dayjs().format('YYYY-MM-DD');
            const filename = `${today}_esemenyek.xlsx`;
            a.download = filename;
            a.click();
        },
        confirmDeleteSelected() {
            this.deleteEventsDialog = true;
        },
        confirmMessageSelected() {
            this.messageDialog = true;
        },
        sendMessage() {
            this.messageSubmitted = true;

            if (
                !this.message.subject ||
                !this.messageText
            ) {
                return;
            }

            this.messageLoading = true;

            if (this.message.subject.trim()) {
                let users = null;
                let parents = null;
                let recipients = [];

                if (this.selectedUsers) {
                    users = this.selectedUsers;
                } else if (this.selectedWaitingUsers) {
                    users = this.selectedWaitingUsers;
                } else {
                    parents = this.selectedAllUsers;
                }

                if (users) {
                    recipients = users.map((event) => {
                        return event.applier.email
                    });
                } else {
                    recipients = parents.map((user) => {
                        return user.email
                    });
                }

                let message = {
                    recipients: recipients,
                    attachment: this.message.attachment,
                    subject: this.message.subject,
                    message: this.messageText,
                    type: 1,
                    eventId: this.event.id,
                };

                this.messageService.create(message).then((response) => {
                    this.messageDialog = false;
                    this.message = {};

                    this.event.messages.push(response.data);

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sikeres művelet!',
                        detail: 'Az üzenet kiküldése sikeres volt.',
                        life: 5000
                    });

                    this.messageLoading = false;
                    this.messageSubmitted = false;
                    this.selectedUsers = null;
                    this.selectedWaitingUsers = null;
                    this.selectedAllUsers = null;
                    this.messageText = '';
                    this.message.subject = '';
                    this.message = {};
                }).catch(e => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Sikertelen művelet!",
                        detail: e.response.data.error,
                        life: 6000,
                    });

                    this.messageLoading = false;
                    this.messageSubmitted = false;
                });
            }
        },
        deleteSelectedEvents() {
            this.loading = true;

            this.selectedEvents.map((event) => {
                this.event = event;
                this.eventService.delete(this.event.id);
            });

            this.deleteEventsDialog = false;

            this.$toast.add({
                severity: 'success',
                summary: 'Sikeres művelet!',
                detail: 'Az események törlése sikeres volt.',
                life: 5000
            });

            this.getAll()
        },
        delete() {
            this.loading = true;

            this.eventService.delete(this.event.id).then(() => {
                this.event = {};
                this.getAll()

                this.$toast.add({
                    severity: 'success',
                    summary: 'Sikeres művelet!',
                    detail: 'Az esemény törlése sikeres volt.',
                    life: 5000
                });

                this.loading = false;
            }).catch(e => {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: e.response.data.error,
                    life: 6000,
                });

                this.loading = false;
            });
        },
        deleteApplicationOperation() {
            this.applicationLoading = true;

            this.applicationService.delete({
                eventId: this.application.eventId,
                userId: this.application.applier.userId ? this.application.applier.userId : null,
                childId: this.application.applier.childId ? this.application.applier.childId : null,
                applyDate: this.application.start
            }).then((response) => {
                this.application = {};
                this.event.applies = response.data.applies
                this.event = response.data;

                this.$toast.add({
                    severity: 'success',
                    summary: 'Sikeres művelet!',
                    detail: 'A jelentkezés törlése sikeres volt.',
                    life: 5000
                });

                this.deleteApplicationDialog = false;
                this.applicationLoading = false;
            }).catch(e => {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: e.response.data.error,
                    life: 6000,
                });

                this.applicationLoading = false;
            });
        },
        initFilters() {
            this.filters = {
                'global': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
                'name': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
                'dateType': {
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS
                },
            }
        },
        initNewEvent(event = {}) {
            this.event = event;
            this.cycleDates = [];
            this.uniqueDates = [];
            this.extraDates = [];
            this.excludeDates = [];
            this.groups = [];
            this.emailTemplates = [];
            this.cycleDateStart = '';
            this.cycleDateEnd = '';
            this.description = '';
            this.descriptionEnglish = '';
            this.shortDescription = '';
            this.shortDescriptionEnglish = '';
            this.image = '';
            this.imageUrl = null;
        },
        initTemplateEvent(eventId) {
            this.event = {};

            this.eventService.get(eventId).then(response => {
                this.event = response.data;
                delete this.event.id;

                if (this.event !== undefined) {
                    console.log(this.event);
                    if (this.event.uniqueDates) {
                        this.uniqueDates = this.event.uniqueDates;
                    }

                    if (this.event.cycleDates) {
                        this.cycleDates = this.event.cycleDates;

                        if (this.event.extraDates.length > 0) {
                            this.extraDates = this.event.extraDates;
                        }
                        // } else {
                        //     this.extraDates.push({
                        //         date: '',
                        //         open: '',
                        //         close: '',
                        //         type: 1,
                        //     });
                        // }

                        if (this.event.excludeDates.length > 0) {
                            this.excludeDates = this.event.excludeDates;
                        }
                        // else {
                        //     this.excludeDates.push({
                        //         date: '',
                        //         open: '',
                        //         close: '',
                        //         type: 0,
                        //     });
                        // }
                    }

                    if (this.event.groups) {
                        this.groups = this.event.groups.map((group) => {
                            delete group.id
                            return {...group}
                        });
                    }

                    if (this.event.emailTemplates) {
                        this.emailTemplates = this.event.emailTemplates.map((emailTemplate) => {
                            delete emailTemplate.id
                            return {...emailTemplate}
                        });
                    }

                    console.log(this.groups);

                    this.description = this.event.description;
                    this.descriptionEnglish = this.event.descriptionEnglish;
                    this.cycleDateStart = this.event.cycleDateStart;
                    this.cycleDateEnd = this.event.cycleDateEnd;
                } else {
                    this.initNewEvent()
                }

                this.eventChoice = '';
                this.eventTemplate = '';
                this.eventChooseDialog = false;
                this.eventDialog = true;
            });
        },
        removeFromWaitingList(event, data) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Biztosan szeretné a jelentkezőt a várólistáról a normál jelentkezők közé helyezni?',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Igen',
                rejectLabel: 'Nem',
                accept: () => {
                    this.waitingListLoading = true;

                    this.applicationService.removeFromWaitingList({
                        eventId: data.eventId,
                        userId: data.applier.userId ? data.applier.userId : null,
                        childId: data.applier.childId ? data.applier.childId : null,
                        applyDate: data.start
                    }).then((response) => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sikeres művelet!',
                            detail: 'A felhasználó sikeresen átkerült a várólistáról a normál jelentkezők közé.',
                            life: 5000
                        });

                        this.event = response.data;
                        this.waitingListLoading = false;
                        this.showEventDialog = false;
                        this.calendarLoaded = false;
                        this.getAll()
                    }).catch(e => {
                        this.$toast.add({
                            severity: "error",
                            summary: "Sikertelen művelet!",
                            detail: e.response.data.error,
                            life: 6000,
                        });

                        this.waitingListLoading = false;
                    });
                }
            });
        },
        getActualDate() {
            const today = new Date();
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1;
            let dd = today.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            return mm + '/' + dd + '/' + yyyy;
        },
        extractLinkLabel(link) {
            const parts = link.split('/');
            return parts[parts.length - 1];
        },
        editEmailTempalte(emailTemplate) {
            this.emailTemplate = {...emailTemplate};
            this.editEmailTemplateDialog = true;
        },
        hideEmailTemplateDialog() {
            this.editEmailTemplateDialog = false;
            this.emailTemplateSubmitted = false;
            this.emailTemplate = {};
        },
        saveEmailTemplate() {
            this.emailTemplateSubmitted = true;
            this.emailTemplateLoading = true;

            this.emailTemplateService.update(this.emailTemplate.id, {
                contentTop: this.emailTemplate.contentTop,
                contentTopEnglish: this.emailTemplate.contentTopEnglish,
                contentBottom: this.emailTemplate.contentBottom,
                contentBottomEnglish: this.emailTemplate.contentBottomEnglish,
            }).then(() => {
                this.editEmailTemplateDialog = false;
                this.showEventDialog = false;
                this.emailTemplate = {};
                this.getAll()

                this.$toast.add({
                    severity: 'success',
                    summary: 'Sikeres művelet!',
                    detail: 'A sablon adatai sikeresen módosultak.',
                    life: 5000
                });

                this.emailTemplateLoading = false;
            }).catch(e => {
                this.$toast.add({
                    severity: "error",
                    summary: "Sikertelen művelet!",
                    detail: e.response.data.error,
                    life: 6000,
                });

                this.emailTemplateLoading = false;
            });
        },
    },
    filters: {
        truncate: function (text, length) {
            if (text.length > length) {
                return text.substring(0, length) + '...'
            } else {
                return text
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';

</style>

<style>
.ck-editor__editable {
    min-height: 300px;
}

.fc { /* the calendar root */
    max-width: 1100px;
    margin: 0 auto;
}

.day-delete {
    padding: 5px !important;
}

.fc-view-harness {
    min-height: 500px !important;
}

.applications-table .p-datatable-tbody > tr > td {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

.applications-table .p-button.p-button-icon-only.p-button-rounded {
    width: 2rem;
    height: 2rem;
}

.p-tooltip.p-component.p-tooltip-right {
    min-width: 250px !important;
}

.p-dropdown {
    min-height: 41px !important;
}

</style>
